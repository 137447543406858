import React, { useState, useEffect } from 'react'
import { EnsurePagePermission } from '../components/auth'
import { firestore } from 'gatsby-theme-firebase'
import Layout from '../components/layout/layout'
import Footer from '../components/layout/footer'
import { Container, Row, Button } from 'react-bootstrap'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import DataTable from 'react-data-table-component'

export default function Leaderboard() {

  const [profile, setProfile] = useState()
  const [isAllowed, setIsAllowed] = useState(false)
  EnsurePagePermission('list', setIsAllowed, setProfile)

  const usersRef = firestore.collection('pin_users')

  const [users, setUsers] = useState([])

  const customStyles = {
    headRow: {
      style: {
        border: 'none',
      },
    },
    subHeader: {
      style: {
        maxHeight: '52px',
        marginTop: '15px',
        marginBottom: '15px',
      },
    },
    headCells: {
      style: {
        color: '#202124',
        fontSize: '14px',
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: '#3771c82b',
        borderBottomColor: '#FFFFFF',
        borderRadius: '10px',
        outline: '1px solid #FFFFFF',
      },
    },
  }

  const columns = [
    {
      selector: 'photo',
      sortable: false,
      width: '100px',
      cell: row => <img height="50px" alt={row.name} src={row.photo} />,
    },
    {
      name: 'Contributor',
      selector: row => row.name,
      sortable: true,
      width: '170px',
      style: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
      },
    },
    {
      name: 'Places',
      selector: row => row.numPlaces,
      sortable: true,
      width: '170px',
      style: {
        color: '#202124',
        fontSize: '14px',
        fontWeight: 500,
      },
    },
  ]

  useEffect(() => {

    usersRef.onSnapshot((querySnapshot) => {
      const items = []
      querySnapshot.forEach((doc) => {
        if (doc.exists) {
          items.push({
            name: doc.data().displayName,
            photo: doc.data().photoURL,
            numPlaces: doc.data().properties.numPlaces
          })
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!")
        }
      })
      setUsers(items)
      // console.log(items)
    })

  }, [])


  return (
    isAllowed &&
    <Layout>
      <GatsbySeo
        titleTemplate='Leaderboard | %s'
      />
      <div className="main-container">
        <header className="text-white">
          <div className="d-flex justify-content-center sm-adjust">
            <h1>Community leaderboard</h1>
          </div>
        </header>
        <section className="leaderboard">
          <Container className="col-10 col-sm-10 col-lg-6 col-xl-5">
            <Row className="py-4">
              <DataTable
                columns={columns}
                data={users}
                defaultSortField="numPlaces"
                defaultSortAsc={false}
                pagination
                customStyles={customStyles}
                highlightOnHover
                noHeader
              />
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </Layout>
  )
}
